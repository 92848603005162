<template>
	<div>
		<div class='a-booking-form-direction'>
			<div class='row'>
				<div class="col-auto" v-for="item in items_types" :key='item.id'>
					<span class='a-booking-form-direction-link'
						:class='{"active": item.id === form.type}'
						@click='item.id === form.type ? false : form = {
							...form,
							type: item.id,
							directions: form.directions.slice(0, 1)
						}, changeTypes()'
					>{{ item.name }}</span>
				</div>
			</div>
		</div>

		<form>
			<div class='a-booking-form-search-group-multi' v-for='(item, i) in form.directions' :key='i'>
				<div class='a-booking-form-search-group'>
					<div class='a-booking-form-search-departure'>
						<select-terminals-charter
							:placeholder='$t("account.booking.content.departure")'
							prepend-icon='departure'
							v-model='item.terminalFrom'
							@input='form = {...form, directions: [...form.directions] }'
							:errors='!item.terminalFrom && errors[`directions.${i}.terminalFrom`]'
							direction='from'
							:type='form.type'
							@clearTerminalTo='clearTerminalTo'
							@updateDate='updateDate($event)'
						/>
					</div>

					<div class='a-booking-form-search-button' @click='changeDirection(item, i)'>
						<v-icon icon='reverse' size='16'/>
					</div>

					<div class='a-booking-form-search-arrivale'>
						<select-terminals-charter
							:placeholder='$t("account.booking.content.arrivale")'
							prepend-icon='arrivale'
							v-model='item.terminalTo'
							@input='form = {...form, directions: [...form.directions] }'
							:errors='!item.terminalTo && errors[`directions.${i}.terminalTo`]'
							direction='to'
							:type='form.type'
							:terminalFrom='form.directions[0].terminalFrom ? form.directions[0].terminalFrom.code : false'
							@updateDate='updateDate($event)'
							@clearTerminalTo='clearTerminalTo'
						/>
					</div>
				</div>
			</div>

			<div class='a-booking-form-row'>
				<div class="a-booking-form-col">
					<a-date-picker-charter
						v-model='form.directions[0].date'
						@change='changeDate($event, 0)'
						:errors='!form.directions[0].date && errors[`directions.0.dateFrom`]'
						:range='form.type === "both"'
						:placeholder="form.type === 'both' ? `${$t('account.form.placeholder.departure')} — ${$t('account.form.placeholder.arrival')}` : `${$t('account.form.placeholder.departure')}`"
						:schedulers='schedulers'
					/>
				</div>
				<div class="a-booking-form-col">
					<a-select
						:placeholder='$t("account.booking.content.choose")'
						:options='items_charterAirClass'
						v-model='form.airClass'
						@input='form = {...form, airClass: form.airClass }'
						:errors='!form.airClass && errors.airClass'
					/>
				</div>
				<template v-if='!edit'>
					<div class="a-booking-form-col">
						<a-autocomplete
							:placeholder='$t("account.booking.content.choose_airlines")'
							v-model='airCompany'
							@input='form.airCompanies = airCompany.length ? [airCompany] : []'
							:requestName='getAutocompleteCharterAirCompany'
							item-value='code'
							actionInitial
							append-icon='arrow-down'
						/>
					</div>
					<div class="a-booking-form-col" :style='form.tourists && form.tourists.length ? "pointer-events: none; opacity: .5": ""'>
						<select-people
							:adults='form.adults'
							:childrenCount='form.childrenCount'
							:children='form.children'
							:placeholder='$t("account.booking.content.count_tourist")'
							@update-people='$event => form = {...form, ...$event}'
							compact
							:errors='!form.adults && errors.adults'
							:tourists='form.tourists'
						/>
					</div>
				</template>
			</div>

			<template v-if='!edit'>
				<select-tourist
					:initialTourists='form.tourists'
					@tourists='form.tourists = $event'
					@add-tourist='form.travelersIds = $event'
					@remove-tourist='form.travelersIds.splice($event, 1)'
				/>
			</template>

			<div class='row a-booking-form-action'>
				<div class="col">
					<template v-if='!edit'>
						<p class='a-booking-form-description' v-if='form.travelersIds.length >= 4'>{{$t("account.booking.content.max_number_tourists")}}</p>
					</template>
				</div>
				<div class="col-auto">
					<v-button :small='!edit' :xxsmall='edit' color='green white--text' @click='charterFlightSearch' :loading='loading'>{{$t("account.booking.content.search")}}</v-button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import SelectTerminalsCharter from "@/views/account/home/components/SelectTerminalsCharter";
	import ADatePickerCharter from '@/views/account/home/components/ADatePickerCharter';
	import SelectPeople from "@/views/account/home/components/SelectPeople";
	import SelectTourist from '@/views/account/home/components/SelectTourist';
	import { getAutocompleteCharterAirCompany, getCharterFlightSchedulers, charterFlightSearch } from '@/services/request';
	import { items_charterAirClass } from '@/helpers/items';

	export default {
		data: () => ({
			open: false,
      items_charterAirClass,
			form: {
				type: "both",
				directions: [
					{
						dateFrom: null,
						dateTo: null,
						terminalFrom: null,
						terminalTo: null
					}
				],
				airClass: null,
				adults: 1,
				childrenCount: 0,
				children: [],
				travelersIds: [],
				airCompanies: [],
				tourists: []
			},
			airCompany: {},
			loading: false,
			errors: {},
			schedulers: {},
			tripName: null
		}),
		props: ["edit", "searchData", "initialTripName"],
		components: {
			SelectTerminalsCharter,
			ADatePickerCharter,
			SelectPeople,
			SelectTourist
		},
		computed: {
			items_types() {
				return [
					{id: "both", name: this.$t("account.booking.content.directions.both")},
					{id: "oneDirection", name: this.$t("account.booking.content.directions.one")}
				]
			},
			local_search_data() {
				return localStorage.getItem('local_search_data') ? JSON.parse(localStorage.getItem('local_search_data')) : {};
			}
		},
		created() {
			if(this.local_search_data.charterFlights) {
				this.form = {...this.form, ...this.local_search_data.charterFlights};
			}
			// this.form.airClass = this.items_airClass[0].id
			if(this.$route.query.source) {
				this.form = JSON.parse(this.$route.query.source)
			} else if(this.searchData) {
				this.form = {...this.form, ...this.searchData};
			}

			if(this.form.directions[0].terminalFrom?.code && this.form.directions[0].terminalTo?.code) {
				return this.getCharterFlightSchedulers();
			}

			this.tripName = this.$route.query?.tripName ?? this.initialTripName;

			if(this.form.tourists?.length === 0 && !this.edit) {
				this.form.travelersIds = [];
			}

			this.form.childrenCount = this.form.children.length

			this.form.airCompanies = [];
		},
		methods: {
            getAutocompleteCharterAirCompany,
			charterFlightSearch() {
				if(this.$store.state.notification.isActive) this.$store.commit('notification/clearMessage');
				this.loading = true;
				let orderId = this.$route.params.orderId ? { orderId: this.$route.params.orderId } : false;

				charterFlightSearch({...this.form, ...orderId}).then(res => {
					if(this.$route.params.storageId === res.data.storageId) {
						this.$emit("update");
					} else {
						this.$router.push({
							name: "charter-flight-search",
							params: {orderId: res.data.orderId, storageId: res.data.storageId, lang: this.$route.params.lang},
							query: {source: JSON.stringify(this.form), tripName: this.tripName}
						})
					}
					localStorage.setItem('local_search_data', JSON.stringify({...this.local_search_data, charterFlights: this.form}));
				}).catch(err => {
					this.errors = err.response.data?.errors ?? {};
				}).finally(() => this.loading = false)
			},
			changeTypes() {
				this.clearTerminalFrom();
				this.clearTerminalTo();
				this.clearDate();
			},
			changeDirection(item, i) {
				let terminalFrom = this.form.directions[i].terminalFrom;
				let terminalTo = this.form.directions[i].terminalTo;

				if(typeof this.form.directions[i].terminalFrom === "object" || typeof this.form.directions[i].terminalTo === "object") {
					this.form.directions[i].terminalTo = terminalFrom;
					this.form.directions[i].terminalFrom = terminalTo;

					this.form = {...this.form, directions: [...this.form.directions] };
				}
			},
			changeDate($event, i) {
				if(this.form.type === 'both') {
					this.form.directions[i].date = $event[0] && $event[1] ? $event : null;
					this.form.directions[i].dateFrom = $event[0] ? this.$moment($event[0]).format('YYYY-MM-DD') : null
					this.form.directions[i].dateTo = $event[1] ? this.$moment($event[1]).format('YYYY-MM-DD') : null
				} else {
					this.form.directions[i].date = $event;
					delete this.form.directions[i].dateTo
					this.form.directions[i].dateFrom = $event ? this.$moment($event).format('YYYY-MM-DD') : null
				}

				this.form = {...this.form, directions: [...this.form.directions] };
			},
			getCharterFlightSchedulers() {
				getCharterFlightSchedulers({
					terminalFrom: this.form.directions[0].terminalFrom.code,
					terminalTo: this.form.directions[0].terminalTo.code,
					type: this.form.type,
				})
					.then(res => this.schedulers = res.data)
			},
			updateDate($event) {
				if($event === "from") {
					this.clearTerminalTo();
				} else if($event === "to") {
					if(this.form.directions[0].terminalTo?.code) {
						return this.getCharterFlightSchedulers();
					}
				}
			},
			clearTerminalFrom() {
				if(this.form.directions[0].terminalFrom?.code) {
					this.form.directions[0].terminalFrom = null;
				}
			},
			clearTerminalTo() {
				if(this.form.directions[0].terminalTo?.code) {
					this.form.directions[0].terminalTo = null;
					this.schedulers = {};
				}
				this.clearDate();
			},
			clearDate() {
				this.form.directions[0].date = null;
				this.form.directions[0].dateFrom = null;

				if(this.form.type === 'both') {
					this.form.directions[0].dateTo = null;
				} else {
					delete this.form.directions[0].dateTo;
				}
			}
		},
		watch: {
			"form.tourists"() {
				localStorage.setItem('local_search_data', JSON.stringify({...this.local_search_data, charterFlights: this.form}));
			}
		}
	}
</script>
