<template>
	<a-select-search
		v-model='innerValue'
		@input='changeInput($event)'
		@blur='changeBlur'
		:placeholder='placeholder'
		:prepend-icon='prependIcon'
		:append-icon='appendIcon'
		:loading='loading'
		:errors='errors'
		@openSelect='openSelect()'
		@input-clear="clearValue(), innerValue.length === 0 ? changeInput(innerValue) : false"
		:readonly='isTerminalFrom'
	>
		<template v-slot:dropdown="{ closeSelect }" v-if='innerValue.length || innerValue.length === 0'>
			<div class='a-select-search__dropdown--item' v-if='!loading && Object.keys(items).length === 0'>
				<p class='a-select-search__dropdown--sub-title large' style='opacity: 1'>{{$t("account.no_results_found_for")}} <strong>“{{innerValue}}”</strong></p>
			</div>
			<div class='a-select-search__dropdown--item' v-else-if='isTerminalFrom'>
				<p class='a-select-search__dropdown--sub-title large' style='opacity: 1'>{{$t("account.first_you_need_to_fill")}} <strong>“{{$t("account.booking.content.departure")}}”</strong></p>
			</div>
			<template v-else>
				<div class='a-select__dropdown--charter'>
					<div class='a-select__dropdown--charter-item' v-for="(options, i) of items" :key="i">
						<p class='a-select__dropdown--charter-title'>{{i}}</p>
						<ul class='a-select__dropdown--list'>
							<li class='a-select__dropdown--item'
								v-for="(option, i) of options"
								:key="i"
								@click="update(option), closeSelect()"
								:class='{"active": option[itemValue] == (value ? value[itemValue] : null)}'
								style='cursor: pointer'
							>
								<span>{{ option[itemName] }}</span>
								<v-icon icon='check' size='12' class='a-select__dropdown--check ml-auto' />
							</li>
						</ul>
					</div>
				</div>
			</template>
		</template>
	</a-select-search>
</template>

<script>
	import { getCharterFlightTerminals } from '@/services/request';

	export default {
		data: () => ({
			innerValue: '',
			loading: false,
			initialItems: [],
			items: []
		}),
		props: {
			"item-value": {
				type: String,
				default: 'code'
			},
			"item-name": {
				type: String,
				default: 'name'
			},
			value: [String, Object],
			placeholder: String,
			"prepend-icon": {
				type: [String, Boolean],
				default: false
			},
			"append-icon": {
				type: [String, Boolean],
				default: false
			},
			errors: [Boolean, Array],
			direction: String,
			type: String,
			terminalFrom: {
				type: [String, Boolean],
				default: false
			}
		},
		computed: {
			isTerminalFrom() {
				return this.direction === "to" && !this.terminalFrom
			}
		},
		created() {
			this.initialInnerValue();
		},
		methods: {
			openSelect() {
				const terminalFrom = this.terminalFrom ? {terminalFrom: this.terminalFrom} : null
				this.loading = true;

				getCharterFlightTerminals({direction: this.direction, type: this.type, ...terminalFrom})
					.then(res => this.initialItems = res.data)
					.finally(() => this.loading = false);
			},
			changeInput(item) {
				this.items = this.$_.groupBy(this.initialItems.filter((el) => el[this.itemName].toLowerCase().includes(item.toLowerCase())), "countryName");
			},
			changeBlur() {
				if(this.value && this.value[this.itemValue]) {
					this.innerValue = this.value[this.itemName]
				} else if(this.innerValue && this.initialItems.length) {
					this.innerValue = this.initialItems[0][this.itemName]
					this.$emit('input', this.initialItems[0]);
					this.$emit('updateDate', this.direction);
				} else if(this.innerValue && this.initialItems.length === 0) {
					this.clearValue();
				}
			},
			update(item) {
				this.$emit('input', item);
				this.innerValue = item[this.itemName];
				this.$emit('updateDate', this.direction);
			},
			clearValue() {
				this.innerValue = '';
				this.$emit('input', '');
				this.$emit('clearTerminalTo');
			},
			initialInnerValue() {
				this.innerValue = this.value ? this.value[this.itemName] : '';
			}
		},
		watch: {
			value() {
				this.initialInnerValue();
			},
			initialItems() {
				this.items = this.$_.groupBy(this.initialItems, "countryName");
			},
			terminalFrom() {
				this.initialItems = [];
			}
		}
	}
</script>

<style lang="scss">
	.a-select__dropdown--charter {
		padding: 10px 0;
		&-title {
			font-size: 16px;
			font-weight: 700;
			padding: 5px 30px;
		}
		.a-select__dropdown--item {
			padding: 8px 15px 8px 50px;
		}
	}
</style>
